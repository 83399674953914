.copy-text__container {
  align-items: center;
  display: flex;
  gap: 2em;
  justify-content: space-between;
  background-color: var(--rsr-color-accent-50);
  border: 1px solid var(--rsr-color-accent-100);
  border-radius: 4px;
  margin: 0.12em 0 0.64em 0;
  padding: 0.32em 0.64em;
}

.copy-text__text {
  color: var(--rsr-color-accent-800);
  font-size: 0.8rem;
}

.box {
  background-color: var(--box-bg-color);
  border: 1px solid var(--box-border-color);
  border-radius: 4px;
  color: var(--box-text-color);
  margin: 0.12em 0 0.64em 0;
  padding: 0.32em 0.64em;
}

.box-primary {
  --box-bg-color: var(--rsr-color-primary-50);
  --box-border-color: var(--rsr-color-primary-100);
  --box-text-color: var(--rsr-color-primary-800);
}

.box-warn {
  --box-bg-color: var(--rsr-color-warn-50);
  --box-border-color: var(--rsr-color-warn-100);
  --box-text-color: var(--rsr-color-warn-800);
}

.box-accent {
  --box-bg-color: var(--rsr-color-accent-50);
  --box-border-color: var(--rsr-color-accent-100);
  --box-text-color: var(--rsr-color-accent-800);
}
